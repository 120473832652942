<template>
  <div>
    <v-btn
      v-for="(color, index) in colors"
      :key="index"
      class="mr-4 mb-4"
      fab
      dark
      small
      :color="color"
      @click="computedValue = color"
    >
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="mr-4 mb-4"
          fab
          dark
          small
          :color="computedValue"
        >
          <v-icon>mdi-palette</v-icon>
        </v-btn>
      </template>
      <v-color-picker
        value="#7417BE"
        v-model="computedValue"
        hide-canvas
        hide-inputs
        show-swatches
        class="mx-auto"
      >
      </v-color-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ColorPalette',
  props: {
    value: {
      type: String,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
          const color = value.substr(0, 7);
          this.$emit('input', color);
        } else {
          this.$emit('input', value);
        }
      }
    }
  }
};
</script>

<style></style>
